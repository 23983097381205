export const getDateToUnixDate = (day, month, year, minutes) => {
  var today = new Date();
  const actualMonth = today.getMonth() + 1;
  const actualYear = today.getFullYear();
  const actualDay = today.getDate();
  const actualHours = today.getHours();
  const actualMinutes = today.getMinutes();
  const actualSeconds = today.getSeconds();

  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;

  return new Date(year, month - 1, day, hours, minutes).getTime() / 1000;
};
