import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMenu,
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../styles/Home.css";
import Program from "../components/Program";
import { useNavigate } from "react-router";

const programData = {
  id: 1,
  title: "Selbständig als Webentwickler",
  ownerName: "Masih Minawal",
  ownerID: "1",
  requirements: "Erfahrung als Webentwickler, 0€ Budget",
  ownerImageURL:
    "https://www.cloud.leefes.com/api/images/uploads/eb8825a72b3a1f884782885943889ff0.jpg",
};

// feed = alle programme laden ohne auth

export default function Home({ feedData }) {
  let navigate = useNavigate();

  return (
    <div className="home">
      <div className="home__topNav" onClick={() => navigate("/menu")}>
        <MenuIcon />
        Mentora
      </div>
      <div className="home__body">
        <div className="home__body_title">Mentoren</div>
        {feedData.map((feed) => (
          <div 
          className="home__body_program"
          onClick={() => navigate("/program/" + feed.id)}>
          <Program data={feed} />
          </div>
        ))}
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;
