import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIosNew,
  MdMenu,
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../styles/Register.css";
import { useNavigate, useParams } from "react-router";
import Elementbox from "../components/Elementbox";
import { isEmpty } from "../functions/isEmpty";

const data = {
  id: 1,
  title: "Dein Mentor",
  text: "Moin ich bin Masih und habe 10 Jahre als selbständiger Webentwickler gearbeitet",
  type: "profilebox",
  profileName: "Masih Minawal",
  profileID: "1",
  profileImageURL:
    "https://www.cloud.leefes.com/api/images/uploads/eb8825a72b3a1f884782885943889ff0.jpg",
};
const data2 = {
  id: 2,
  title: "Programmbeschreibung",
  text: "Am Anfang machen wir so, dann machen wir so und dann machen wir so",
  type: "description",
  profileName: "Masih Minawal",
  profileID: "1",
  profileImageURL:
    "https://www.cloud.leefes.com/api/images/uploads/eb8825a72b3a1f884782885943889ff0.jpg",
};

const fetchedProgramData = {
  title: "Selbständig als Webentwickler",
};

// fetchen Programdata nach ID ohne Auth

export default function Register({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  signUp,
  username,
  setUsername,
  isUploading,
  setIsUploading,
  programID,
  setProgramID,
}) {
  let navigate = useNavigate();
  const { id } = useParams();

  // if loggedIn Redirect to the program
  useEffect(() => {
    if (!isEmpty(authToken)) {
      setIsUploading(false);

      if (!isEmpty(id)) {
        navigate("/mentoring/" + id);
      } else {
        window.history.go(-1);
      }
    }
  }, [authToken]);

  useEffect(() => {
    if(!isEmpty(id)){
      setProgramID(id.toString());
    }
  }, [authToken]);

  const signUpAndDisableButton = () => {
    setIsUploading(true);
    signUp();
  };

  return (
    <div className="programdetails">
      <div className="programdetails__top">
      <div
          className="programdetails__top_left"
          onClick={() => navigate("/")}
        >
          <BackIcon />
        </div>
        <div className="programdetails__top_middle">Registrieren </div>
        <div className="programdetails__top_right"></div>
      </div>
      <div className="programdetails__body">
        <div className="register__body_title">
          Um fortzufahren bitte <br />
          Registrieren
        </div>
        <div className="register__body_inputs">
          <div className="register__body_inputs_inputbox">
            <input
              className="register__body_inputs_inputbox_input"
              placeholder="Name eingeben"
              type="text"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="register__body_inputs_inputbox">
            <input
              className="register__body_inputs_inputbox_input"
              placeholder="Emailadresse eingeben"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="register__body_inputs_inputbox">
            <input
              className="register__body_inputs_inputbox_input"
              placeholder="Passwort eingeben"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className="register__body_cta"
          onClick={() => signUpAndDisableButton()}
        >
          Registrieren
        </div>
        <div className="trennlinie"></div>
        <div className="register__body_title">Oder Einloggen</div>
        <div
          className="register__body_switch"
          onClick={() => navigate("/login/" + id)}
        >
          Zum Login
        </div>
      </div>
    </div>
  );
}

const BackIcon = styled(MdArrowBackIosNew)`
  font-size: 25px;
  color: #575757;
`;
