import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIosNew,
  MdMenu,
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../styles/Programdetails.css";
import { useNavigate, useParams } from "react-router";
import Elementbox from "../components/Elementbox";

const data = {
  id: 1,
  title: "Dein Mentor",
  text: "Moin ich bin Masih und habe 10 Jahre als selbständiger Webentwickler gearbeitet",
  type: "profilebox",
  profileName: "Masih Minawal",
  profileID: "1",
  profileImageURL:
    "https://www.cloud.leefes.com/api/images/uploads/eb8825a72b3a1f884782885943889ff0.jpg",
};
const data2 = {
  id: 2,
  title: "Programmbeschreibung",
  text: "Am Anfang machen wir so, dann machen wir so und dann machen wir so",
  type: "description",
  profileName: "Masih Minawal",
  profileID: "1",
  profileImageURL:
    "https://www.cloud.leefes.com/api/images/uploads/eb8825a72b3a1f884782885943889ff0.jpg",
};

const fetchedProgramData = {
  title: "Selbständig als Webentwickler",
};

// fetchen Programdata nach ID ohne Auth

export default function Programdetails({ authToken }) {
  const [programData, setProgramData] = useState([]);
  const [reseponseProgramData, setResponseProgramData] = useState("");

  let navigate = useNavigate();
  const { id } = useParams();

  // get FeedData from Programs
  useEffect(async () => {
    const res = await fetch(
      "https://www.cloud.mentora.cc/api/programs/noAuthOnlyGET.php?id=" + id,
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res
      .text()
      .then((value) => setResponseProgramData(JSON.parse(value)));
  }, []);

  // userdata in state packen
  useEffect(() => {
    if (reseponseProgramData !== "") {
      setProgramData(reseponseProgramData[0]);
    }
  }, [reseponseProgramData, authToken]);

  return (
    <div className="programdetails">
      <div className="programdetails__top">
        <div className="programdetails__top_left" onClick={() => navigate("/")}>
          <BackIcon />
        </div>
        <div className="programdetails__top_middle">
          {programData.programTitle}
        </div>
        <div className="programdetails__top_right">
          <div
            className="programdetails__top_right_startButton"
            onClick={() => navigate("/mentoring/" + id)}
          >
            Anschauen
          </div>
        </div>
      </div>
      <div className="programdetails__body">
        <Elementbox type={"profileBox"} data={programData} />
        <Elementbox type={"descriptionBox"} data={programData} />
      </div>
    </div>
  );
}

const BackIcon = styled(MdArrowBackIosNew)`
  font-size: 25px;
  color: #575757;
`;
