import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdCancel,
  MdMenu,
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../styles/Menu.css";
import Program from "../components/Program";
import { useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";

const programData = {
  id: 1,
  title: "Selbständig als Webentwickler",
  ownerName: "Masih Minawal",
  ownerID: "1",
  requirements: "Erfahrung als Webentwickler, 0€ Budget",
  ownerImageURL:
    "https://www.cloud.leefes.com/api/images/uploads/eb8825a72b3a1f884782885943889ff0.jpg",
};

// feed = alle programme laden ohne auth

export default function Menu({ authToken, destroySession }) {
  let navigate = useNavigate();

  const logout = () => {
    destroySession();
    navigate("/");
  };

  return (
    <div className="menu">
      <div className="menu__topNav" onClick={() => window.history.go(-1)}>
        <CancelIcon />
        Mentora
      </div>
      <div className="menu__body">
        <div className="menu__body_title">Menü</div>
        <div className="menu__body_menuoption" onClick={() => navigate("/")}>
          Mentorenprogramme
        </div>
        <div
          className="menu__body_menuoption"
          onClick={() => navigate("/becomementor")}
        >
          Mentor werden
        </div>
        <div
          className="menu__body_menuoption"
          onClick={() => navigate("/legal")}
        >
          Datenschutz & Impressum
        </div>
        {!isEmpty(authToken) ? (
          <div className="menu__body_menuoption" onClick={() => logout()}>
            Ausloggen
          </div>
        ) : (
          <div className="menu__body_menuoption" onClick={() => navigate("/register")}>
            Registrieren / Einloggen
          </div>
        )}
      </div>
    </div>
  );
}

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;
