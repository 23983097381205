import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIosNew,
  MdCheckCircle,
  MdMenu,
  MdMore,
  MdMoreHoriz,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";
import "../styles/Programdetails.css";
import { useNavigate, useParams } from "react-router";
import { isEmpty } from "../functions/isEmpty";

export default function CreateProgram({userData, authToken, isUploading, setIsUploading, refresh, setRefresh,}) {
  const [program, setProgram] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [responseProgram, setResponseProgram] = useState("");
  const [responseTasks, setResponseTasks] = useState("");
  const [modalTaskID, setModalTaskID] = useState();
  const [redirectTo, setRedirectTo] = useState("");

  const [imageUploads, setImageUploads] = useState([]);
    const { id } = useParams();
  const pathname = window.location.pathname;
  let navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/register/");
    }
  }, [authToken]);

 // initialize tasks
 useEffect(async () => {
    const res = await fetch(
      "https://www.cloud.mentora.cc/api/tasks/index.php?programID=" + id,
      {
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res
      .text()
      .then((value) => setResponseTasks(JSON.parse(value)));
  }, [userData, id, authToken]);

  // tasks mit geparstem content in state packen
  useEffect(() => {
    if(responseTasks !== ""){
      let helpArray = JSON.parse(JSON.stringify(responseTasks));
      helpArray.map(task => {
        task.content = JSON.parse(task.content)
      })


helpArray.sort((a, b) => parseInt(a.position) - parseInt(b.position));

      setTasks(helpArray);
    }
  }, [responseTasks, program, authToken]);

// initialize program
  useEffect(async () => {
      const res = await fetch(
    "https://www.cloud.mentora.cc/api/programs/index.php?id=" + id,
    {
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "GET",
      // body: JSON.stringify({
      //  companyname: "dancemonkey"
      // })
    }
  );
  const resp = await res
    .text()
    .then((value) => setResponseProgram(JSON.parse(value)));
}, [userData, authToken, id]);

// program in state packen
useEffect(() => {
  if (responseProgram !== "") {
        setProgram(responseProgram[0]);
  }
}, [responseProgram, authToken]);

// onChange der Inputs verarbeiten durch kopie des states
  const mutatePrograms = (state, value) => {
    let helpArray = JSON.parse(JSON.stringify(program));
    if(state == "programTitle"){
      helpArray.programTitle = value;
      }
if(state == "userName"){
  helpArray.userName = value;
  }
if(state == "userDescription"){
  helpArray.userDescription = value;
  }
if(state == "programDescription"){
  helpArray.programDescription = value;
  }
if(state == "programRequirements"){
  helpArray.programRequirements = value;
  }
setProgram(helpArray);
  }

  // eine neue task erstellen durch upload und dann weiterleitung
  const createTask = async(endlink) => {
    let helpArray = tasks;
    let newPosition = 1;
    helpArray.map(task => {if (parseInt(task.position)> newPosition){newPosition=parseInt(task.position)}})

    newPosition++;
    let newPositionString = (newPosition).toString();

if(!isEmpty(authToken)){
  const resa = await fetch(
    "https://www.cloud.mentora.cc/api/tasks/index.php",
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        programID: id,
        position: newPositionString,
        title: "Neue Lektion",
        content: JSON.stringify([{type:"image",url:"https://www.cloud.mentora.cc/api/images/placeholder/imagePlaceholderShort.svg"},{type:"text",content:"Hallo i bims"}]),  
      }),
    }
  );
    await resa.text().then((value) =>      uploadProfileIMG(endlink+value));
}
  }


  const uploadProfileIMG = async (endlink) => {
    const files = document.getElementById("imgInpProfileImage").files;
    const formData = new FormData();
    setIsUploading(true);

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0) {
      const res = await fetch(
        "https://www.cloud.mentora.cc/api/images/process.php",
        {
          headers: {
            Authorization: `Bearer ${authToken?.JWT}`,
          },
          method: "POST",
          body: formData,
        }
      );

      await res.text().then((value) => handleUpload(endlink, value));
    } else {
      handleUpload(endlink);
    }
  };

  // die daten vom program updaten
  const handleUpload = async (endlink, imgURL) => {
    // jedes bild einzelnd hochladen
    setIsUploading(true);

if(imgURL == "" && program.userProfileImage == ""){
  imgURL = "https://www.cloud.mentora.cc/api/images/placeholder/profilbild.svg";
}else if(program.userProfileImage != "" && imgURL == ""){
imgURL = program.userProfileImage;
}

    const resa = await fetch(
      "https://www.cloud.mentora.cc/api/programs/index.php?id="+id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          userID: userData.id,
          userProfileImage: imgURL,
          userName: program.userName,
          userDescription: program.userDescription,
          programTitle: program.programTitle,
          programDescription: program.programDescription,
          programRequirements: program.programRequirements
        }),
      }
    );
    
    await resa.text().then((value) => uploadEnds(endlink));
  };

  const uploadEnds = (endlink) => {
    setIsUploading(false);
    setRefresh(!refresh);
      navigate(endlink)
    
  };

  const insertLocalImage = (e) => {
    let file = e.target.files[0];
    console.log(file);
    setImageUploads({
      localURL: URL.createObjectURL(file),
      files: file,
    });
  };


  const openModal = (id) => {
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
    setModalTaskID(id);
  }
  
  const closeModal = () => {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }
  
  const deleteTask = () => {
  
    fetch(
      "https://www.cloud.mentora.cc/api/tasks/index.php?id="+modalTaskID.id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          status: 0,
        }),
      }
    );

    let helpArray;
    let helpArray2 = [];
    let PositiontoDelete;
    // 1. die position herausfinden
    tasks.map(task => {
      if(parseInt(task.id) == parseInt(modalTaskID)){
        PositiontoDelete = task.position  
      }
      })

    // 2. alle Tasks hoeher als die ID um 1 verringern 
    helpArray = JSON.parse(JSON.stringify(tasks));
    helpArray.map(task => {
    if(parseInt(task.position) >= parseInt(PositiontoDelete)){
      task.position = parseInt(task.position) - 1;
      task.position = task.position.toString();
    }
    })

    // 3. die Task ausm Array entfernen
    helpArray.map(task => {
    if(parseInt(task.id) != parseInt(modalTaskID)){
    helpArray2.push(task)
    }
    })



    setTasks(helpArray2);

    fetch(
      "https://www.cloud.mentora.cc/api/tasks/index.php?id="+modalTaskID,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          status: 0,
        }),
      }
    );

    helpArray2.map(task => {
      fetch(
        "https://www.cloud.mentora.cc/api/tasks/index.php?id="+task.id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            position: task.position,
          }),
        }
      );
       // await resa.text().then((value) => uploadEnds());
    })
    closeModal();

  }

  const resortToPosition = async (newPosition) => {
    let helpArray = JSON.parse(JSON.stringify(tasks));
// 0. Die Position der ausgeweahlten Task finden
let PositiontoDelete;
// 1. die position herausfinden
tasks.map(task => {
  if(parseInt(task.id) == parseInt(modalTaskID)){
    PositiontoDelete = task.position  
  }
  })


    helpArray.map(task => {
// 1. alle die ne hoehere position haben um 1 erhöhen
if(parseInt(task.position) >= parseInt(newPosition) && parseInt(task.position) <= parseInt(PositiontoDelete)){
  task.position = parseInt(task.position) + 1;
  task.position = task.position.toString();
}
    })

    helpArray.map(task => {
      // 2. die ausgewaehlte task auf die neue position setzen
      if(task.id == modalTaskID){
        task.position = newPosition;
        task.position = task.position.toString();
      }
          })

          // 3. nach position neu sortieren
          helpArray.sort((a, b) => parseInt(a.position) - parseInt(b.position));
setTasks(helpArray);


helpArray.map(task => {
  fetch(
    "https://www.cloud.mentora.cc/api/tasks/index.php?id="+task.id,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        position: task.position,
      }),
    }
  );
   // await resa.text().then((value) => uploadEnds());
})
closeModal();
  }

  const openTaskAndSave = (taskID) => {
    uploadProfileIMG("/createTask/" + taskID); 
  }

  const saveAndBackToMentor = () => {
    uploadProfileIMG("/becomementor"); 
  }

  return (
    <div className="programdetails">
      <div className="programdetails__top">
        <div
          className="programdetails__top_left"
          onClick={() => navigate("/becomementor")}
        >
          <BackIcon />
        </div>
        <div className="programdetails__top_middle">Programm erstellen</div>
        <div className="programdetails__top_right">
        <div
            className="programdetails__top_right_startButton"
            onClick={() => saveAndBackToMentor()}
          >
            Speichern
          </div>
        </div>
      </div>
      <div className="programdetails__body">
      <div className="elementbox">
          <div className="elementbox__title">Programmtitel</div>
          <div className="elementbox__element">
            <div className="elementbox__element_text"><input
              className="register__body_inputs_inputbox_input"
              placeholder="Programmtitel eingeben"
              type="text"
              value={program?.programTitle}
              onChange={(e) => 
                mutatePrograms("programTitle", e.target.value)
              }
            /></div>
          </div>
        </div>
        <div className="elementbox">
          <div className="elementbox__title">Dein Mentor</div>
          <div className="elementbox__element">
            <div className="elementbox__element_top">
              {!isEmpty(imageUploads)? (
                <img
                src={imageUploads.localURL}
                className="elementbox__element_top_image"
              />
              ) : (
                <>
                {program?.userProfileImage == ""? (
                   <img
                   className="elementbox__element_top_image"
                   src={"https://www.cloud.mentora.cc/api/images/placeholder/profilbild.svg"}
                 />
                ) : (
                   <img
                   className="elementbox__element_top_image"
                   src={program?.userProfileImage}
                 />
                )}
             
              </>
              )}
              
<input
                accept=".jfif, .pjpeg, .pjp, .jpeg, .jpg, .png"
                type="file"
                id={"imgInpProfileImage"}
                name="files[]"
                className="info_replyBox_addImage_input"
                onChange={(e) => insertLocalImage(e)}
              />
              <div className="elementbox__element_top_profileName">
            <input
              className="register__body_inputs_inputbox_input"
              placeholder="Name eingeben"
              type="text"
              value={program?.userName}
              onChange={(e) => {
                mutatePrograms("userName", e.target.value)
              }}
            />
          </div>
            </div>
            <div className="elementbox__element_text"><input
              className="register__body_inputs_inputbox_input"
              placeholder="Beschreibe dich"
              type="text"
              value={program?.userDescription}
              onChange={(e) => {
                mutatePrograms("userDescription", e.target.value)
              }}
            /></div>
          </div>
        </div>
        <div className="elementbox">
          <div className="elementbox__title">Programmbeschreibung</div>
          <div className="elementbox__element">
            <div className="elementbox__element_text"><input
              className="register__body_inputs_inputbox_input"
              placeholder="Programmbeschreibung eingeben"
              type="text"
              value={program?.programDescription}
              onChange={(e) => {
                mutatePrograms("programDescription", e.target.value)
              }}
            /></div>
          </div>
        </div>
        <div className="elementbox">
          <div className="elementbox__title">Vorbedingungen</div>
          <div className="elementbox__element">
            <div className="elementbox__element_text"><input
              className="register__body_inputs_inputbox_input"
              placeholder="Welche Vorbedingungen gibt es?"
              type="text"
              value={program?.programRequirements}
              onChange={(e) => {
                mutatePrograms("programRequirements", e.target.value)
              }}
            /></div>
          </div>
        </div>

        <div className="mentoring__body_tasks">
          {tasks.map((task) => (
            <div className="mentoring__body_tasks_task">
              <div
                className="mentoring__body_tasks_task_text"
               onClick={() => openTaskAndSave(task.id)}
              >
                {task.position + ". " + task.title} 
              </div>
              <div className="program__top_more"
          onClick={() => openModal(task.id)}
          ><MoreIcon /></div>
            </div>
          ))}
          <div className="mentoring__body_tasks_task_newTask">
            <div
              className="mentoring__body_tasks_task_text"
              onClick={() => createTask("/createTask/")}
            >
              + Neue Aufgabe erstellen
            </div>
          </div>
        </div>
        <div className="mentoring__body_tasks_task_newTool">
          <div
            className="mentoring__body_tasks_task_text"
           // onClick={() => navigate("/createTask/")}
          >
            + Tool hinzufügen
          </div>
        </div>
      </div>

      <div id="myModal" class="modal">

<div class="modal-content">
<div className="modal-content_option modal-content_option_bottomLine"
   onClick={() => deleteTask()}
  >
Löschen
  </div>
  <div className="modal-content_option modal-content_option_bottomLine"
  >
    <select onChange={(e) => resortToPosition(e.target.value)} value={"Verschieben"}>
  <option value="Verschieben">Verschieben</option>
{tasks.map(task => {
   return( <option value={task.position} 
   onClick={() => resortToPosition(task.position)}
   >An Position {task.position}</option>)
})}
</select>
  </div>
  <div className="modal-content_option"
  onClick={() => closeModal()}
  >
Zurück
  </div>
</div>
</div>
    </div>
  );
}

const BackIcon = styled(MdArrowBackIosNew)`
  font-size: 25px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircle)`
  font-size: 34px;
  color: #e8e8e8;
`;


const MoreIcon = styled(MdMoreHoriz)`
  font-size: 18px;
  color: #9c9c9c;
`;
