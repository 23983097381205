import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import Home from "./screens/Home";
import Programdetails from "./screens/Programdetails";
import Mentoring from "./screens/Mentoring";
import Menu from "./screens/Menu";
import TaskDetails from "./screens/TaskDetails";
import BecomeMentor from "./screens/BecomeMentor";
import CreateProgram from "./screens/CreateProgram";
import CreateTask from "./screens/CreateTask";
import Tools from "./screens/Tools";
import Landingpage from "./screens/Landingpage";
import Register from "./screens/Register";
import Login from "./screens/Login";
import Legal from "./screens/Legal";
import ModalComponent from "./components/ModalComponent";

export default function App() {
  /* Auth Token */
  const [authToken, setAuthToken] = useState();

  /* userdata */
  const [userData, setUserData] = useState([]);
  const [responseUser, setResponseUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

/* Membership and Tasks Data */
const [membershipData, setMembershipData] = useState([]);
const [responseMembershipData, setResponseMembershipData] = useState("");

/* My Programs Data */
const [myPrograms, setMyPrograms] = useState([]);
const [responseMyPrograms, setResponseMyPrograms] = useState("");

  /* Feed Data Data */
  const [sortedFeedData, setSortedFeedData] = useState([]);
  const [feedData, setFeedData] = useState([]);
  const [responseFeedData, setResponseFeedData] = useState("");

  /* refresh */
  const [refresh, setRefresh] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  /* settings */
  const [darkModeOn, setDarkModeOn] = useState(false);
  const [mobile, setMobile] = useState(false);

  /* active Page */
  const { id } = useParams();
  const [programID, setProgramID] = useState("");
 




  // ---- all my memberships mit authToken
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(userData)) {
      const res = await fetch("https://www.cloud.mentora.cc/api/memberships/index.php?userID="+userData.id, {
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      });
      const resp = await res
        .text()
        .then((value) => setResponseMembershipData(JSON.parse(value)));
    }
  }, [authToken, userData, refresh]);

  // userdata in state packen
  useEffect(() => {
    if (responseMembershipData !== "") {
      setMembershipData(responseMembershipData);
    }
  }, [responseMembershipData, authToken, refresh]);

  // ---- all my created programs mit authToken
useEffect(async () => {
  if (!isEmpty(authToken) && !isEmpty(userData)) {
    const res = await fetch("https://www.cloud.mentora.cc/api/programs/index.php?userID="+userData.id, {
      headers: {
        Authorization: `Bearer ${authToken.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "GET",
      // body: JSON.stringify({
      //  companyname: "dancemonkey"
      // })
    });
    const resp = await res
      .text()
      .then((value) => setResponseMyPrograms(JSON.parse(value)));
  }
}, [authToken, userData, refresh]);

// userdata in state packen
useEffect(() => {
  if (responseMyPrograms !== "") {
    setMyPrograms(responseMyPrograms);
  }
}, [responseMyPrograms, authToken, refresh]);


  // ---- activeProgram für Mentoring ProgramDetails ohne auth
  // ---- activeProgramTasks für Mentoring und TaskDetails


  // ---- get FeedData from Programs
  useEffect(async () => {
    const res = await fetch(
      "https://www.cloud.mentora.cc/api/programs/noAuthOnlyGET.php",
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res
      .text()
      .then((value) => setResponseFeedData(JSON.parse(value)));
  }, [refresh]);

  // userdata in state packen
  useEffect(() => {
    if (responseFeedData !== "") {
      setFeedData(responseFeedData);
    }
  }, [responseFeedData, authToken]);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setResponseUser("");
      setUserData([]);
      setAuthToken();
      setMembershipData([]);
      setMyPrograms([]);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch("https://www.cloud.mentora.cc/api/authenticate", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(
      "https://www.cloud.mentora.cc/api/signupAndEnrollToProgram.php",
      {
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          username: username,
          programID: programID,
        }),
      }
    );

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  // get userData
  useEffect(async () => {
    if (!isEmpty(authToken)) {
      const res = await fetch("https://www.cloud.mentora.cc/api/users/", {
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      });
      const resp = await res
        .text()
        .then((value) => setResponseUser(JSON.parse(value)));
    }
  }, [authToken]);

  // userdata in state packen
  useEffect(() => {
    if (responseUser !== "") {
      setUserData(responseUser);
    }
  }, [responseUser, authToken]);

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Home feedData={feedData} />} />
          {["/program", "/program/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<Programdetails authToken={authToken} />}
            />
          ))}
          {["/mentoring", "/mentoring/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<Mentoring authToken={authToken} membershipData={membershipData} userData={userData} />}
            />
          ))}
          {["/menu", "/menu/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Menu authToken={authToken} destroySession={destroySession} />
              }
            />
          ))}
          {["/task", "/task/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<TaskDetails authToken={authToken} membershipData={membershipData} setMembershipData={setMembershipData} userData={userData}
              refresh={refresh} setRefresh={setRefresh}
              />}
            />
          ))}
          {["/becomementor", "/becomementor/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<BecomeMentor authToken={authToken} 
              userData={userData} feedData={myPrograms}
              refresh={refresh} setRefresh={setRefresh}
              />}
            />
          ))}
          {["/createProgram", "/createProgram/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<CreateProgram authToken={authToken} 
              isUploading={isUploading} setIsUploading={setIsUploading}
refresh={refresh} setRefresh={setRefresh}  
userData={userData}
              />}
            />
          ))}
          {["/createTask", "/createTask/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<CreateTask authToken={authToken}
            isUploading={isUploading} setIsUploading={setIsUploading}
            refresh={refresh}
            setRefresh={setRefresh}
            />
            }
            />
          ))}
          {["/tools", "/tools/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<Tools authToken={authToken} />}
            />
          ))}
          {["/landing", "/landing/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={<Landingpage authToken={authToken} />}
            />
          ))}
          {["/register", "/register/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Register
                  authToken={authToken}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  signUp={signUp}
                  username={username}
                  setUsername={setUsername}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  programID={programID}
                  setProgramID={setProgramID}
                />
              }
            />
          ))}
          {["/login", "/login/:id"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Login
                  authToken={authToken}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  signIn={signIn}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  programID={programID}
                  setProgramID={setProgramID}
                />
              }
            />
          ))}
          {["/legal", "/legal/:id"].map((path, index) => (
            <Route key={index} path={path} element={<Legal />} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}
