import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components/macro";
import { Button, Modal, Input } from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%`,
  };
}

function ModalComponent({
  type,
  open,
  setOpen,
  modalSwitchLogin,
  setModalSwitchLogin,
  email,
  setEmail,
  password,
  setPassword,
  username,
  setUsername,
  signIn,
  signUp,
  content,
  disabled,
  cta,
}) {
  const [modalStyle] = useState(getModalStyle);

var priceToEuroCent;

  if (type === "login") {
    return (
      <div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <div style={modalStyle} className="app__modal">
            <div className="app__modalTitle">
              <div className="app__modalTitleFirst">
                <div>
                  <img
                    className="app__modalImage"
                    src="https://firebasestorage.googleapis.com/v0/b/leefes-90e30.appspot.com/o/uploads%2Fleefes-logo-ohne-text.svg?alt=media&token=c559ed3d-0099-4f89-a706-89348d20319e"
                    alt=""
                  />
                </div>
                <div className="app__modalTitleFirstText">
                  Willkommen bei Leefes
                </div>
              </div>
              <div className="app__modalTitleSecond">
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
            </div>
            {modalSwitchLogin ? (
              <div>
                <div className="app__modalSwitch">
                  <div
                    className="app__modalSwitchButton true"
                    onClick={() => setModalSwitchLogin(true)}
                  >
                    <div>Einloggen</div>
                  </div>
                  <div
                    className="app__modalSwitchButton"
                    onClick={() => setModalSwitchLogin(false)}
                  >
                    <div>Registrieren</div>
                  </div>
                </div>

                <div>
                  <div className="app__modalSignup">
                    <div className="app__modalSignupTitle">Email</div>
                    <div className="app__modalSignupInputDiv">
                      <input
                        className="app__modalSignupInput"
                        placeholder="Email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="app__modalSignupTitle">Passwort</div>
                    <div className="app__modalSignupInputDiv">
                      <input
                        className="app__modalSignupInput"
                        placeholder="Passwort"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="app__modalSignupButton">
                      button
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="app__modalSwitch">
                  <div
                    className="app__modalSwitchButton"
                    onClick={() => setModalSwitchLogin(true)}
                  >
                    <div>Einloggen</div>
                  </div>
                  <div
                    className="app__modalSwitchButton true"
                    onClick={() => setModalSwitchLogin(false)}
                  >
                    <div>Registrieren</div>
                  </div>
                </div>

                <div>
                  <div className="app__modalSignup">
                    <div className="app__modalSignupTitle">
                      Angezeigter Name
                    </div>
                    <div className="app__modalSignupInputDiv">
                      <input
                        className="app__modalSignupInput"
                        placeholder="z.B. Künstler-, Firmen-, oder Vor und Nachname"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="app__modalSignupTitle">Email</div>
                    <div className="app__modalSignupInputDiv">
                      <input
                        className="app__modalSignupInput"
                        placeholder="Email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="app__modalSignupTitle">Passwort</div>
                    <div className="app__modalSignupInputDiv">
                      <input
                        className="app__modalSignupInput"
                        placeholder="Passwort"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="app__modalSignupButton">
                      buton
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  if (type === "newAppointment") {
    return (
      <div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <div style={modalStyle} className="app__modal">
            <div className="app__modalTitle">
              <div className="app__modalTitleFirst">
                <div>
                  <img
                    className="app__modalImage"
                    src="https://firebasestorage.googleapis.com/v0/b/leefes-90e30.appspot.com/o/uploads%2Fleefes-logo-ohne-text.svg?alt=media&token=c559ed3d-0099-4f89-a706-89348d20319e"
                    alt=""
                  />
                </div>
                <div className="app__modalTitleFirstText">
                  Termin hinzugefügt
                </div>
              </div>
              <div className="app__modalTitleSecond">
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
            </div>
            <div className="app__modalTermin">
              {content.map((cont) => {
                if (cont.type === "anbieter") {
                  return (
                    <div className="app__modalTerminRow">
                      <div className="app__modalTerminRowLeft">
                        <img
                          className="app__modalTerminRowLeftImg br100"
                          alt=""
                          src={cont.imgURL}
                        />
                      </div>
                      <div className="app__modalTerminRowRight">
                        <span className="bold"> {cont.name} </span>
                        <br /> {cont.Adresse}
                      </div>
                    </div>
                  );
                }
                if (cont.type === "leistung") {
                  return (
                    <div className="app__modalTerminRow">
                      <div className="app__modalTerminRowLeft">
                        <img
                          className="app__modalTerminRowLeftImg"
                          alt=""
                          src={cont.imgURL}
                        />
                      </div>
                      <div className="app__modalTerminRowRight">
                        <span className="bold"> {cont.title}</span> <br />{" "}
                        {priceToEuroCent(cont.payment)}
                      </div>
                    </div>
                  );
                }
                if (cont.type === "termin") {
                  return (
                    <div className="app__modalTerminRow">
                      <div className="app__modalTerminRowLeft">
                        <div className="app__modalTerminRowLeftDiv">
                          <div className="app__modalTerminRowLeftDivTop">
                            {cont.wochenTag}
                          </div>
                          <div className="app__modalTerminRowLeftDivBottom">
                            {cont.datumsTag}
                          </div>
                        </div>
                      </div>
                      <div className="app__modalTerminRowRight">
                        <span className="bold"> {cont.datum}</span> <br />{" "}
                        {cont.uhrzeit}
                      </div>
                    </div>
                  );
                }
              })}
              <div className="app__modalSignupButton">
                btn
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  if (type === "deletedAppointment") {
    return (
      <div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <div style={modalStyle} className="app__modal">
            <div className="app__modalTitle">
              <div className="app__modalTitleFirst">
                <div>
                  <img
                    className="app__modalImage"
                    src="https://firebasestorage.googleapis.com/v0/b/leefes-90e30.appspot.com/o/uploads%2Fleefes-logo-ohne-text.svg?alt=media&token=c559ed3d-0099-4f89-a706-89348d20319e"
                    alt=""
                  />
                </div>
                <div className="app__modalTitleFirstText">Termin abgesagt</div>
              </div>
              <div className="app__modalTitleSecond">
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
            </div>
            <div className="app__modalTermin">
              {content.map((cont) => {
                if (cont.type === "anbieter") {
                  return (
                    <div className="app__modalTerminRow">
                      <div className="app__modalTerminRowLeft">
                        <img
                          className="app__modalTerminRowLeftImg br100"
                          alt=""
                          src={cont.imgURL}
                        />
                      </div>
                      <div className="app__modalTerminRowRight">
                        <span className="bold"> {cont.name} </span>
                        <br /> {cont.Adresse}
                      </div>
                    </div>
                  );
                }
                if (cont.type === "leistung") {
                  return (
                    <div className="app__modalTerminRow">
                      <div className="app__modalTerminRowLeft">
                        <img
                          className="app__modalTerminRowLeftImg"
                          alt=""
                          src={cont.imgURL}
                        />
                      </div>
                      <div className="app__modalTerminRowRight">
                        <span className="bold"> {cont.title}</span> <br />{" "}
                        {cont.payment}
                      </div>
                    </div>
                  );
                }
                if (cont.type === "termin") {
                  return (
                    <div className="app__modalTerminRow">
                      <div className="app__modalTerminRowLeft">
                        <div className="app__modalTerminRowLeftDiv">
                          <div className="app__modalTerminRowLeftDivTop">
                            {cont.wochenTag}
                          </div>
                          <div className="app__modalTerminRowLeftDivBottom">
                            {cont.datumsTag}
                          </div>
                        </div>
                      </div>
                      <div className="app__modalTerminRowRight">
                        <span className="bold"> {cont.datum}</span> <br />{" "}
                        {cont.uhrzeit}
                      </div>
                    </div>
                  );
                }
              })}
              <div className="app__modalSignupButton">
               btn
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  if (type === "AreYouSure") {
    return (
      <div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <div style={modalStyle} className="app__modal">
            <div className="app__modalTitle">
              <div className="app__modalTitleFirst">
                <div>
                  <img
                    className="app__modalImage"
                    src="https://firebasestorage.googleapis.com/v0/b/leefes-90e30.appspot.com/o/uploads%2Fleefes-logo-ohne-text.svg?alt=media&token=c559ed3d-0099-4f89-a706-89348d20319e"
                    alt=""
                  />
                </div>
                <div className="app__modalTitleFirstText">
                  Wirklich Dienstleistung löschen?
                </div>
              </div>
              <div className="app__modalTitleSecond">
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
            </div>
            <div className="app__modalTermin">
              <div className="app__modalSignupButton">
              btn
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;

const CloseIcon = styled(MdClose)`
  font-size: 33px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
