import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIosNew,
  MdMenu,
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../styles/Register.css";
import { useNavigate, useParams } from "react-router";
import Elementbox from "../components/Elementbox";
import { isEmpty } from "../functions/isEmpty";


export default function Login({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  signIn,
  isUploading,
  setIsUploading,
  programID,
  setProgramID,
}) {
  let navigate = useNavigate();
  const { id } = useParams();

  // if loggedIn Redirect to the program
  useEffect(() => {
    if (!isEmpty(authToken)) {
      setIsUploading(false);

        navigate("/");
    }
  }, [authToken]);

  useEffect(() => {
    setProgramID(id.toString());
  }, [authToken]);

  const signInAndDisableButton = () => {
    setIsUploading(true);
    signIn();
  };

  return (
    <div className="programdetails">
      <div className="programdetails__top">
        <div
          className="programdetails__top_left"
          onClick={() => navigate("/mentoring/" + id)}
        >
          <BackIcon />
        </div>
        <div className="programdetails__top_middle">Login </div>
        <div className="programdetails__top_right"></div>
      </div>
      <div className="programdetails__body">
        <div className="register__body_title">
          Um fortzufahren bitte <br />
          Einloggen
        </div>
        <div className="register__body_inputs">
          <div className="register__body_inputs_inputbox">
            <input
              className="register__body_inputs_inputbox_input"
              placeholder="Emailadresse eingeben"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="register__body_inputs_inputbox">
            <input
              className="register__body_inputs_inputbox_input"
              placeholder="Passwort eingeben"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className="register__body_cta"
          onClick={() => signInAndDisableButton()}
        >
          Einloggen
        </div>
        <div className="trennlinie"></div>
        <div className="register__body_title">
          Noch kein Konto? Registrieren
        </div>
        <div
          className="register__body_switch"
          onClick={() => navigate("/register/" + id)}
        >
          Zur Registrierung
        </div>
      </div>
    </div>
  );
}

const BackIcon = styled(MdArrowBackIosNew)`
  font-size: 25px;
  color: #575757;
`;
